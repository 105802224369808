import Bugsnag, { Config } from '@bugsnag/js'

// NOTE: テスト実行時の notifyReleaseStages 警告が邪魔だからログ無効化
const loggerOptions = (): Pick<Config, 'logger'> => {
  // eslint-disable-next-line unicorn/no-null
  return import.meta.env.RAILS_ENV === 'test' ? { logger: null } : {}
}

const userOptions = (): Pick<Config, 'user'> => {
  if (!window.APP.rails.user) return {}

  const { id, email } = window.APP.rails.user
  return {
    user: { id: id.toString(), email },
  }
}

// NOTE: jsのシンタックスエラーも通知させる為にエントリーポイントを別にしているが他エントリーポイントからも必要となるため
window.bugsnagClient = Bugsnag.start({
  releaseStage: import.meta.env.RAILS_ENV,
  apiKey: 'e3b85ed4c2f933a5f6be43986a212453',
  enabledReleaseStages: ['staging', 'production'],
  ...loggerOptions(),
  ...userOptions(),
})
